export function formatEpochTime(epochTime) {
  const date = new Date(epochTime * 1000); // Convert epoch to milliseconds

  // Get date components
  const month = date.toLocaleString('en-US', { month: 'short' });
  const day = date.getDate();
  const year = date.getFullYear();

  // Get time components
  const hours = date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

  // Get timezone information
  const timezoneName = Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(date).find(part => part.type === 'timeZoneName').value;
  const timezoneOffset = -date.getTimezoneOffset(); // in minutes
  const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60).toString();
  const timezoneMinutes = Math.abs(timezoneOffset % 60).toString().padStart(2, '0');
  const gpsOffset = `${timezoneOffset >= 0 ? '-' : '+'}${timezoneHours}:${timezoneMinutes}`;

  // Construct the final string
  const formattedTime = `${month} ${day} ${year} ${hours} ${timezoneName} (GPS ${gpsOffset})`;

  return formattedTime;
}