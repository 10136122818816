import React, { useState } from 'react';
import Modal from 'react-modal';

import { fetchJsonWithCache } from '../utils/fetchJsonWithCache';

import OperationGraphs from './OperationGraphs';
import ChannelDataGraphs from './ChannelDataGraphs';
import SiteData from './SiteData';


Modal.setAppElement('#root');

const ResponsiveTabs = ({ activeTab, setActiveTab, tabs, setActiveSubTab, electricChannels, magneticChannels }) => {
  const handleTabChange = (value) => {
    setActiveTab(value);
    if (value === 'electric') {
      setActiveSubTab(electricChannels[0]?.tag || null);
    } else if (value === 'magnetic') {
      setActiveSubTab(magneticChannels[0]?.tag || null);
    } else {
      setActiveSubTab(null);
    }
  };
  return (
    <div>
      {/* Tabs for larger screens */}
      <nav className="hidden sm:flex space-x-8 border-b border-gray-200">
        {tabs.map(({ title, value }) => (
          <button
            key={value}
            className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${activeTab === value ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
            onClick={() => handleTabChange(value)}
          >
            {title}
          </button>
        ))}
      </nav>

      {/* Dropdown for smaller screens */}
      <div className="sm:hidden">
        <select
          className="form-select mt-1 block w-full px-3 py-1 text-lg font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          value={activeTab}
          onChange={(e) => handleTabChange(e.target.value)}
        >
          {tabs.map(({ title, value }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const DisplaySiteModal = ({ isOpen, onClose, site }) => {
  const [activeTab, setActiveTab] = useState('main');
  const [activeSubTab, setActiveSubTab] = useState(null);
  const [stationData, setStationData] = useState(site.data_site.recmeta);
  const [recId, setRecId] = useState(site.data_site.recmeta.recordingid);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const electricChannels = site.data_site.recmeta.channels
    .filter(channel => channel.tag.startsWith('E'))
    .sort((a, b) => a.tag.localeCompare(b.tag, undefined, { numeric: true }));

  const magneticChannels = site.data_site.recmeta.channels
    .filter(channel => channel.tag.startsWith('H'))
    .sort((a, b) => a.tag.localeCompare(b.tag, undefined, { numeric: true }));
    
  const allTabs = [
    { title: 'Recording', value: 'main' },
    { title: 'Battery', value: 'battery' },
    { title: 'Temperature', value: 'temperature' },
    { title: 'Electric Channels', value: 'electric' },
    { title: 'Magnetic Channels', value: 'magnetic' },
  ];

  const updateRecording = (e) => {
    const newRecordingId = e.target.value;
    const directory = `data/${site.station}/${site.data_site.folder}/${newRecordingId}`;
    setLoading(true);
    setError(null);
    fetchJsonWithCache(directory, 'recmeta')
    .then(data => {
        setLoading(false);
        setRecId(newRecordingId);
        setStationData({
          'recordingid' : newRecordingId,
          'gps_lat' : data["timing"]["gps_lat"],
          'gps_lon' : data["timing"]["gps_lon"],
          'gps_alt' : data["timing"]["gps_alt"],
          'channels' : data["channel_map"]["mapping"],
          'status' : data["status"],
          'acqtime' : data["acqtime"],
          'start' : data["start"],
          'stop' : data["stop"],
          'sw_version' : data["sw_version"],
          'receiver_model' : data["receiver_model"],
          'receiver_commercial_name' : data["receiver_commercial_name"],
          'rec_status' : data["rec_status"],
          'company_name' : data["layout"]["Company_Name"],
          'operator' : data["layout"]["Operator"],
          'station_name' : data["layout"]["Station_Name"],
          'survey_name' : data["layout"]["Survey_Name"],
          'bat_ext' : data["bat_ext"],
          'temp_cel' : data["temp_cel"]    
        })
    })
    .catch(err => {
        console.error('Error fetching channel data:', err);
        setError('Failed to load channel data');
        setLoading(false);
    });

  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Site Details"
      className="fixed inset-0 flex justify-center items-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-none h-full overflow-hidden overflow-y-auto">
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <h2 className="text-2xl font-semibold">Site {site.id} {site.data_site.recordings.length === 1 ? (
              recId.recordingid
            ) : (
              <select
                id="recordings"
                defaultValue={recId}
                onChange={updateRecording}
                className="px-3 py-1 text-xs font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {site.data_site.recordings.map(rec => (
                  <option key={rec} value={rec}>
                    {rec}
                  </option>
                ))}
              </select>
            )}</h2>

            
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        
        <div className="px-6 py-4">
          
        
          <ResponsiveTabs 
            activeTab={activeTab} 
            setActiveTab={setActiveTab} 
            tabs={allTabs} 
            setActiveSubTab={setActiveSubTab} 
            electricChannels={electricChannels} 
            magneticChannels={magneticChannels} 
          />


          {(activeTab === 'electric' || activeTab === 'magnetic') && (
            <div className="flex space-x-4 mt-4 overflow-x-auto">
              {(activeTab === 'electric' ? electricChannels : magneticChannels).map(channel => (
                <button
                  key={channel.tag}
                  className={`whitespace-nowrap px-3 py-2 rounded ${activeSubTab === channel.tag ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-600'}`}
                  onClick={() => setActiveSubTab(channel.tag)}
                >
                  {channel.tag}
                </button>
              ))}
            </div>
          )}

        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">{error}</p>}

          <div className="flex-none overflow-auto">
            {activeTab === 'main' && (
                <SiteData
                  site={site}
                  recordingid={recId}
                  stationData={stationData}
                />
            )}
            {activeTab === 'battery' && (
              <OperationGraphs
                site={site}
                param="battery"
                unit="V"
                title="Battery Data"
                yAxisTitle="Battery"
                recordingid={recId}
                stationData={stationData}
              />
            )}
            {activeTab === 'temperature' && (
              <OperationGraphs
                site={site}
                param="temperature"
                unit="°C"
                title="Temperature Data"
                recordingid={recId}
                 stationData={stationData}
              />
            )}
            {(activeTab === 'electric' || activeTab === 'magnetic') && activeSubTab && (
              <ChannelDataGraphs
                site={site}
                channel={activeTab === 'electric' ? electricChannels.find(c => c.tag === activeSubTab) : magneticChannels.find(c => c.tag === activeSubTab)}
                directory={`data/${site.station}/${site.data_site.folder}/${recId}`}
                 stationData={stationData}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DisplaySiteModal;
