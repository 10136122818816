import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { fetchJsonWithCache } from '../utils/fetchJsonWithCache';
import { extractDataFromStats } from '../utils/extractDataFromStats';

const OperationGraphs = ({ site, param, unit, title, recordingid, stationData}) => {
  const [plotData, setPlotData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // console.log('recordingid',recordingid)
  useEffect(() => {
    if (!site) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      
      const directory = `data/${site.station}/${site.data_site.folder}/${recordingid}`;
      try {
        const data = await fetchJsonWithCache(directory);
        const extractedData = extractDataFromStats(data, param);
        // console.log('data',data)
        const processedData = extractedData.data.map((dt) => ({
          time: (parseInt(stationData.start) + parseInt(dt.x) * (60*6)) * 1000,
          value: (dt.y / 1000).toFixed(2),
        }));

        setPlotData(processedData);
      } catch (error) {
        console.error(`Error fetching ${param} data:`, error);
        setError(`Failed to load ${param} data.`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [site, param, recordingid, stationData]);
// console.log(plotData)
  return (
    <div>
      <h3 className="text-xl py-5">{title}</h3>
      {loading && <p>Loading {title.toLowerCase()}...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {plotData && plotData.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={plotData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="time"
              type="number"
              domain={[Math.min(...plotData.map(d => d.time)),Math.max(...plotData.map(d => d.time))]}
              tickFormatter={(unixTime) => new Date(unixTime).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
              tick={{ fontSize: 10 }}
            />
            <YAxis 
              domain={['dataMin-1', 'dataMax+1']} 
              tick={{ fontSize: 10 }}
              unit={unit}
              tickFormatter={(value) => Math.floor(value)} 
              allowDecimals={false}
            />
            <Tooltip 
              labelFormatter={(label) => new Date(label).toLocaleString(([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}))} 
              contentStyle={{fontSize:10}}
            />
            <Line 
              type="monotone" 
              dataKey="value" 
              stroke="blue" 
              dot={{strokeWidth:2, r:1}}
              activedot={{strokeWidth:2, r:3}}
              isAnimationActive={false}
              strokeWidth={0}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default OperationGraphs;
