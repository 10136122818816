export function convertSecondsToTime(seconds) {
  const months = Math.floor(seconds / (30 * 24 * 60 * 60));
  if (months > 6) {
    return '>6 Months';
  }

  const days = Math.floor((seconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);

  let result = '';
  if (months > 0) result += `${months} month${months > 1 ? 's' : ''} `;
  if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
  if (hours > 0) result += `${hours} hour${hours > 1 ? 's' : ''} `;
  if (minutes > 0) result += `${minutes} minute${minutes > 1 ? 's' : ''} `;

  return result.trim() || '0 minutes';
}
