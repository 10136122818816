import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';

import config from '../config';
import { isTimestampYoungerThan } from '../utils/isTimestampYoungerThan';

import FullscreenModal from './DisplaySiteModal';
import ManageUserModal from './ManageUserModal';
import CustomControl from './CustomControl';

import logo from '../images/phoenix.png';
import userIconUrl from '../svg/user.svg';
import StationIconUrl from '../svg/mtu.svg';
import QuestionMarkIconUrl from '../svg/question.svg'; 

// Function to create an HTML-based DivIcon for pulsating icons
const createPulsingIcon = (iconUrl, pulseColorClass) => {
  return new L.DivIcon({
    className: '',
    html: `
      <div class="pulse-container">
        <div class="pulse-background ${pulseColorClass}"></div>
        <img src="${iconUrl}" class="station-icon-img" />
      </div>
    `,
    iconSize: [30, 30], // Adjust the icon size as needed
    iconAnchor: [15, 15], // Center the icon
  });
};

const StationIconOn = createPulsingIcon(StationIconUrl, 'pulse-green');
const StationIconUnknownActive = createPulsingIcon(QuestionMarkIconUrl, 'pulse-blue');
const StationIconPending = createPulsingIcon(StationIconUrl, 'pulse-orange');
const StationIconUknown = L.icon({
  iconUrl: QuestionMarkIconUrl,
  className: 'station-icon-unknown'
});
const StationIconOff = L.icon({
  iconUrl: StationIconUrl,
  className: 'station-icon-off'
});

const UserIcon = L.icon({
  iconUrl: userIconUrl,
  className: 'user-icon'
});

const UserLocationMarker = ({ setUserPosition }) => {
  const [position, setPosition] = useState(null);
  const map = useMap();

  useEffect(() => {
    map.locate().on('locationfound', function (e) {
      setPosition(e.latlng);
      setUserPosition(e.latlng);
      map.setView(e.latlng, map.getZoom());
    });
  }, [map, setUserPosition]);

  return position === null ? null : (
    <Marker position={position} icon={UserIcon} />
  );
};

const CustomMarkers = ({ stations, onMarkerClick }) => {
  return stations.map((station) => {
    return Object.entries(station.station_data).map(([key, site]) => {
      let icon;
      
      // Determine the icon based on the recording status
      if (site.recmeta.rec_status === 'in_progress') {
        // Pulsing green for in progress
        icon = isTimestampYoungerThan(site.recmeta.stop, 2) ? StationIconOn : StationIconPending;
      } else if (site.recmeta.rec_status === 'completed') {
        icon = StationIconOff; // Grey icon for completed
      } else if (!site.recmeta.rec_status && isTimestampYoungerThan(site.recmeta.stop, 0.5)) {
        // Unknown but probably running (pulsing blue)
        icon = StationIconUnknownActive;
      } else if (!site.recmeta.rec_status) {
        // Unknown with old firmware (question mark icon)
        icon = StationIconUknown;
      }

      return (
        <Marker
          key={key}
          position={[site.recmeta.gps_lat, site.recmeta.gps_lon]}
          icon={icon}
          eventHandlers={{
            click: () => {
              onMarkerClick({ id: key, data_site: site, last_update: station.last_update, station: station.station });
            },
          }}
        />
      );
    });
  });
};

const Map = ({ userRole, handleLogout }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [isManageUserModalOpen, setIsManageUserModalOpen] = useState(false);
  const [stationsData, setStationsData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [userPosition, setUserPosition] = useState(null); // Track user position

  const mapRef = React.useRef();

  useEffect(() => {
    // Step 1: Fetch the list of stations from the API
    fetch(`${config.apiBaseUrl}/stations/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Step 2: For each station, fetch its detailed data
        const stationFetches = data.data.map(station =>
          fetch(`${config.baseUrl}/data/${station.slug}/station_data.json`)
            .then(response => response.json())
            .then(stationData => ({
              station: station.slug,
              station_data: stationData.station_data,
              last_update: stationData.last_update,
            }))
            .catch(err => {
              console.error(`Failed to fetch data for station ${station.name}:`, err);
              return null; // Return null on error
            })
        );

        // Step 3: Resolve all promises and filter out any that failed
        Promise.all(stationFetches)
          .then(stationsData => {
            const validStations = stationsData.filter(station => station !== null); // Filter out any stations that failed to fetch
            setStationsData(validStations);
            setLoading(false); // Data fetched, stop loading
          })
          .catch(error => {
            console.error('Error fetching station data:', error);
            setError(error); // Save the error for display
            setLoading(false); // Stop loading even if there was an error
          });
      })
      .catch(error => {
        console.error('Error fetching stations list:', error);
        setError(error); // Save the error for display
        setLoading(false); // Stop loading even if there was an error
      });
  }, []);

  const handleMarkerClick = (site) => {
    setSelectedSite(site);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSite(null);
  };

  const handleOpenManageUserModal = () => {
    setIsManageUserModalOpen(true);
  };

  const handleCloseManageUserModal = () => {
    setIsManageUserModalOpen(false);
  };

  const handleLogoClick = () => {
    const map = mapRef.current;
    if (map) {
      if (userPosition) {
        map.setView(userPosition, 2);
      } else {
        map.setView([43.65107, -79.347015], 2); // Toronto coordinates
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      // If a modal is open, close it
      if (modalIsOpen) {
        setModalIsOpen(false); // Close the FullscreenModal
        setSelectedSite(null);
      } else if (isManageUserModalOpen) {
        setIsManageUserModalOpen(false); // Close the ManageUserModal
      } else {
        // Otherwise, zoom out to the world view
        handleLogoClick();
      }
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  if (loading) {
    return <div>Loading map...</div>; // Display a loading message while fetching data
  }

  if (error) {
    return <div>Error loading map: {error.message}</div>; // Display an error message if fetch fails
  }

  return (
    <div className="h-screen relative">
      <img 
        src={logo} 
        alt="Zoom to world view" 
        className="filter drop-shadow-lg absolute top-4 left-1/2 transform -translate-x-1/2 w-16 h-auto z-50 cursor-pointer"
        onClick={handleLogoClick}
      /> 
      <MapContainer center={[43.65107, -79.347015]} zoom={4} zoomDelta={1.5} zoomSnap={2} className="h-full z-40" ref={mapRef}>
        <TileLayer
          attribution='Tiles &copy; Esri &mdash; Esri'
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
        />
        <UserLocationMarker setUserPosition={setUserPosition} />
        <MarkerClusterGroup chunkedLoading zoomToBoundsOnClick>
          <CustomMarkers stations={stationsData} onMarkerClick={handleMarkerClick} />
        </MarkerClusterGroup>
        <CustomControl onOpenManageUserModal={handleOpenManageUserModal} />
      </MapContainer>
      {selectedSite && (
        <FullscreenModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          site={selectedSite}
        />
      )}
      <ManageUserModal
        isOpen={isManageUserModalOpen}
        onRequestClose={handleCloseManageUserModal}
        onRequestLogout={handleLogout}
      />
    </div>
  );
};

export default Map;
