export function extractDataFromStats(dt, parameters) {
    
    const formattedData = {
    'recordingid': dt['recordingid'] || null,
    'numchannels': dt['numchannels'] || null, 
    'duration(min)': dt['duration(min)'] || null, 
    'data': dt['perminstats'].map((num, index)=>{
        // eslint-disable-next-line default-case
        switch (parameters) {
            case 'battery':
                return {
                    x: index,
                    y: num['battery(mV)']
                }
            case 'temperature':
                return {
                    x: index,
                    y: num['temperature']*1000
                }
            // case 'electric':
            //     return {
            //         x: index,
            //         y: 'yo'
            //     }
            default:
                return null;
        }
        })
    };

    return formattedData;
}