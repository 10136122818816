import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAxqkCcofD4jXS3sZVaglVkJB0HyuptgpE",
  authDomain: "volcanet-e44ca.firebaseapp.com",
  projectId: "volcanet-e44ca",
  storageBucket: "volcanet-e44ca.appspot.com",
  messagingSenderId: "208960297713",
  appId: "1:208960297713:web:d2340e9839e59577f06750"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export default firebase;
