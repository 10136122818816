import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import config from '../config';

Modal.setAppElement('#root');

const user = {
  'name': 'Test User',
  'email': 'test@atlas-monitoring.net',
  'company': 'Phoenix Geophysics'
};

const ResponsiveTabs = ({ activeTab, setActiveTab, tabs }) => {
  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  return (
    <div>
      {/* Tabs for larger screens */}
      <nav className="hidden sm:flex space-x-8 border-b border-gray-200">
        {tabs.map(({ title, value }) => (
          <button
            key={value}
            className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${activeTab === value ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
            onClick={() => handleTabChange(value)}
          >
            {title}
          </button>
        ))}
      </nav>

      {/* Dropdown for smaller screens */}
      <div className="sm:hidden">
        <select
          className="form-select mt-1 block w-full px-3 py-1 text-lg font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          value={activeTab}
          onChange={(e) => handleTabChange(e.target.value)}
        >
          {tabs.map(({ title, value }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const ManageUserModal = ({ isOpen, onRequestClose, onRequestLogout }) => {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('information');

  const tabs = [
    { title: 'User', value: 'information' },
    { title: 'Stations', value: 'stations' },
  ];

  useEffect(() => {
    if (isOpen && activeTab === 'stations') {
      setLoading(true);
      fetch(`${config.apiBaseUrl}/stations/`) // Adjust this URL to match your API endpoint
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch servers');
          }
          return response.json();
        })
        .then(data => {
          setServers(data.data || []); // Assuming 'data' has a 'data' property containing the servers
          setError(null);
        })
        .catch(error => {
          setError(error.message);
          setServers([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isOpen, activeTab]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Manage User"
      className="fixed inset-0 flex justify-center items-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-none sm:max-w-lg md:max-w-2xl lg:max-w-4xl h-full overflow-hidden overflow-y-auto">
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <h2 className="text-2xl font-semibold">Your Profile</h2>
          <button onClick={onRequestClose} className="text-gray-400 hover:text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="px-6 py-4">
          <ResponsiveTabs 
            activeTab={activeTab} 
            setActiveTab={setActiveTab} 
            tabs={tabs} 
          />

          <div className="mt-4">
            {activeTab === 'information' && (
              <div>
                <h3 className="text-xl mb-4 border-b pb-2">Your Information</h3>
                <div className="space-y-2">
                  <div><strong className="text-gray-600">e-mail:</strong> <span className="text-gray-800">{user.email}</span></div>
                  <div><strong className="text-gray-600">Name:</strong> <span className="text-gray-800">{user.name}</span></div>
                  <div><strong className="text-gray-600">Company:</strong> <span className="text-gray-800">{user.company}</span></div>
                  <div>
                    <button
                      onClick={onRequestLogout}
                      className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
                    >
                      Log out
                    </button>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'stations' && (
              <div>
                <h3 className="text-xl mb-4 border-b pb-2">Your Stations</h3>
                <div>
                  {loading && <p className="text-gray-600">Loading stations info...</p>}
                  {error && <p className="text-red-500">Error: {error}</p>}
                  {!loading && !error && servers.length === 0 && <p className="text-gray-600">No station available.</p>}
                  <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {servers.map((server, index) => (
                      <li key={index} className="p-4 bg-gray-100 rounded-lg shadow-sm">
                        <h4 className="text-lg font-semibold text-gray-800 mb-2">{server.name}</h4>
                        <div><strong className="text-gray-600">Host:</strong> <span className="text-gray-800">{server.sftp_host}</span></div>
                        <div><strong className="text-gray-600">Port:</strong> <span className="text-gray-800">{server.sftp_port}</span></div>
                        <div><strong className="text-gray-600">Username:</strong> <span className="text-gray-800">{server.sftp_username}</span></div>
                        <div><strong className="text-gray-600">Private Key:</strong> <span className="text-gray-800">{server.private_key_path}</span></div>
                        {server.folders && server.folders.length > 0 && (
                          <div>
                            <strong className="text-gray-600">Folders:</strong>
                            <ul className="mt-2 ml-4 list-disc text-gray-600">
                              {server.folders.map((folder, folderIndex) => (
                                <li key={folderIndex}>{folder}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManageUserModal;
