
import React from 'react';

import config from '../config';
import { formatEpochTime } from '../utils/timeUtils'; 
import { convertSecondsToTime } from '../utils/convertSecondsToTime';
import { isTimestampYoungerThan } from '../utils/isTimestampYoungerThan';
import Tooltip from './Tooltip';

const SiteData = ({ site , stationData}) => {

  const recStatus = stationData.rec_status === 'in_progress'
    ? isTimestampYoungerThan(stationData.stop, 2)
      ? 'In Progress'
      : 'Pending'
    : stationData.rec_status === 'completed'
    ? 'Completed'
    : 'Unknown';
  const currentTime = Date.now(); // Current time in milliseconds
  const thirtyMinutesInMs = 30 * 60 * 1000; // 30 minutes in milliseconds
  const timeDifference = currentTime - (stationData.stop*1000);

  let tooltipMessage = "Firmware is too old. Please update it to see the status of the receiver.";

  if (timeDifference < thirtyMinutesInMs) {
    tooltipMessage += "\n \nLast update was less than 30 minutes ago, the recording might be in progress.";
  }
  return (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 w-full max-w-none sm:max-w-lg md:max-w-2xl lg:max-w-4xl m-auto">

                {/* Recording Section */}
                <div>
                  <h3 className="text-xl mb-4 border-b py-4">Recording</h3>
                  <div>
                    <div><strong>Survey Name:</strong> {stationData.survey_name}</div>
                    <div><strong>Station Name:</strong> {stationData.station_name}</div>
                    <div><strong>Company Name:</strong> {stationData.company_name}</div>
                    <div><strong>Receiver Type:</strong> {stationData.receiver_commercial_name}</div>
                    <div><strong>Operator:</strong> {stationData.operator}</div>
                    <div><strong>Channels:</strong> {stationData.channels.map(c=>c.tag).sort((a, b) => a>b).join(', ')}</div>
                    <div className='py-3'>
                      <a 
                        href={`${config.baseUrl}/data/${site.station}/${site.data_site.folder}/${stationData.recordingid}/recmeta.json`} 
                        target='_blank' 
                        rel="noreferrer" 
                        className='px-2 py-1 text-xs font-small text-center text-white bg-blue-700 rounded-md hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                      >
                      Open recmeta.json
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor" 
                        className="w-4 h-4 ml-2 inline"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth="2" 
                          d="M14 3H21V10M10 14L21 3M4 4V20H20"
                        />
                      </svg>
                      </a>
                    </div>
                  </div>
                </div>

                {/* Timing Section */}
                <div>
                  <h3 className="text-xl mb-4 border-b py-4">Timing</h3>
                  <div>
                    <div>
                      <strong>Recording status:</strong>{' '}
                      {recStatus}
                      {recStatus === 'Unknown' && (
                        <Tooltip message={tooltipMessage} />
                      )}
                    </div>
                    <div><strong>Start time:</strong> {formatEpochTime(stationData.start)}</div>
                   
                    {stationData.rec_status==='in_progress' ? (
                    <div><strong>Last TS update:</strong> {formatEpochTime(stationData.stop)}</div>
                  ) : (
                    <div><strong>Stop time:</strong> {formatEpochTime(stationData.stop)}</div>
                  )}
                    
                    <div><strong>Duration:</strong> {convertSecondsToTime(stationData.acqtime)}</div>
                  </div>

                {/* Operation Status Section */}
                  <h3 className="text-xl mb-4 border-b py-4 pt-7">Operation Status</h3>
                  <div>
                    <div><strong>Battery:</strong> {stationData.bat_ext.toFixed(1)} V </div>
                    <div><strong>Temperature:</strong> {stationData.temp_cel}&deg;C </div>
                    <div><strong>Latitude:</strong> {stationData.gps_lat.toFixed(3)}</div>
                    <div><strong>Longitude:</strong> {stationData.gps_lon.toFixed(3)}</div>
                    <div><strong>Altitude:</strong> {stationData.gps_alt.toFixed(2)}</div>
                  </div>
                </div>
              </div>
              
  );
};

export default SiteData;
