import React, { useState } from 'react';

const Tooltip = ({ message }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTooltip = () => setIsOpen(!isOpen);

  const InfoIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-5 h-5 text-blue-500"
    >
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 16v-4m0-4h.01" />
    </svg>
  );

  return (
    <div className="relative inline-block">
      <div
        className="cursor-pointer"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onClick={toggleTooltip}
      >
        <InfoIcon />
      </div>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-64 bg-blue-50 p-4 rounded-lg shadow-lg z-10">
          <div className="flex items-start">
            <InfoIcon />
            <div className="ml-2 text-sm text-gray-700">
              {message.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
