import { saveToIndexedDB, getFromIndexedDB, deleteFromIndexedDB} from '../utils/indexedDB';
import config from '../config';

export function fetchJsonWithCache(directory, file = 'stats') {
  return new Promise((resolve, reject) => {
    const cacheKey = btoa(`${directory}-${file}`); // Base64 encode the key based on file type
    const intervall = 5 * 60 * 1000;

    getFromIndexedDB(cacheKey).then(cachedItem => {
      if (cachedItem) {
        const { data, timestamp } = cachedItem;

        if (Date.now() - timestamp < intervall) {
          resolve(data);
          return;
        }
        else {
          deleteFromIndexedDB(cacheKey).catch(err => {
            console.error('Failed to delete outdated data from IndexedDB:', err);
          });
        }
      }

      // Fetch new data if no valid cache
      fetch(`${config.baseUrl}/${directory}/${file}.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Store new data in IndexedDB
          saveToIndexedDB(cacheKey, {
            data,
            timestamp: Date.now(),
          });
          resolve(data);
        })
        .catch(error => {
          console.error('Failed to fetch data:', error);
          reject(error); // Reject the promise if an error occurs
        });
    }).catch(error => {
      console.error('Failed to access IndexedDB:', error);
      reject(error);
    });
  });
}
