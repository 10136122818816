import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import userIconUrl from '../svg/user.svg';

const CustomControl = ({ onOpenManageUserModal }) => {
  const map = useMap();

  useEffect(() => {
    const CustomControl = L.Control.extend({
      options: {
        position: 'topright',
      },
      onAdd: function () {
        const container = L.DomUtil.create('div', 'userprofile-button');
        container.innerHTML=`<img src="${userIconUrl}" alt="User Profile">`;
        container.onclick = () => {
          onOpenManageUserModal();
        };
        return container;
      },
    });

    const customControlInstance = new CustomControl();
    map.addControl(customControlInstance);

    return () => {
      map.removeControl(customControlInstance);
    };
  }, [map, onOpenManageUserModal]);

  return null;
};

export default CustomControl;
