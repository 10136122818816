import { openDB } from 'idb';

const DB_NAME = 'phenixDB';
const STORE_NAME = 'sitedata';
const VERSION = 1;

export async function initDB() {
    const db = await openDB(DB_NAME, VERSION, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME);
            }
        },
    });
    return db;
}

export async function saveToIndexedDB(key, data) {
    const db = await initDB();
    await db.put(STORE_NAME, data, key);
}

export async function getFromIndexedDB(key) {
    const db = await initDB();
    return await db.get(STORE_NAME, key);
}

export async function deleteFromIndexedDB(key) {
    const db = await initDB();
    await db.delete(STORE_NAME, key);
}

export async function clearIndexedDB() {
    const db = await initDB();
    await db.clear(STORE_NAME);
}
