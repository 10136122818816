import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { fetchJsonWithCache } from '../utils/fetchJsonWithCache';

const ChannelDataGraphs = ({ site, channel, directory , stationData}) => {
    const [channelData, setChannelData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        setLoading(true);
        setError(null);

        fetchJsonWithCache(directory)
        .then(data => {
            const channelDt = data.perminstats.map(permin => permin.channelstats.find(stat => stat.id === channel.idx) || {
              minvalue: 0,
              maxvalue: 0,
              avgvalue: 0,
              saturationcount:0
            });
            setChannelData(channelDt);
            setLoading(false);
        })
        .catch(err => {
            console.error('Error fetching channel data:', err);
            setError('Failed to load channel data');
            setLoading(false);
        });
    }, [channel, directory]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="text-red-500">{error}</p>;
    if (!channelData) return <p>No data available for {channel.tag}.</p>;

    const dataspecs = [
        { title: 'minvalue', color: 'blue' },
        { title: 'maxvalue', color: 'red' },
        { title: 'avgvalue', color: 'green' }
    ];
    const plotData = channelData.map((cdb, cindex) => {
      return {
        time: (parseInt(stationData.start) + parseInt(cindex)*(6*60)) * 1000,
        minvalue: cdb['minvalue'],
        maxvalue: cdb['maxvalue'],
        avgvalue: cdb['avgvalue']
      }
    });

    const saturationData = channelData.map((cdb, cindex) => {
      return{
        time: (parseInt(stationData.start) + parseInt(cindex)*(6*60)) * 1000,
        value: cdb['saturationcount']
      }
    });

    return (
        <div className="space-y-8">
            <div>
                <h4 className="text-lg font-semibold py-5">Time Series Level</h4>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={plotData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="time" 
                          type="number" 
                          domain={[Math.min(...plotData.map(d => d.time)),Math.max(...plotData.map(d => d.time))]}
                          tickFormatter={(unixTime) => new Date(unixTime).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                          tick={{ fontSize: 10 }}
                        />
                        <YAxis 
                          tick={{ fontSize: 10 }}
                          domain={['auto', 'auto']} 
                          label={{ value: "Signal (V)", angle: -90, position: 'insideLeft', style:{fontSize:12} }}
                        />
                        <Tooltip 
                          labelFormatter={(label) => new Date(label).toLocaleString()} 
                          formatter={(value, name) => [`${name}: ${value}`]}
                          contentStyle={{fontSize:10}}
                        />
                        {dataspecs.map((series) => (
                          <Line 
                            dataKey={series.title} 
                            stroke={series.color} 
                            isAnimationActive={false} 
                            dot={{strokeWidth:2, r:1}}
                            activedot={{strokeWidth:2, r:3}}
                            strokeWidth={0}
                          />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div>
                <h4 className="text-lg font-semibold py-5">Saturated Frames</h4>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart 
                      data={saturationData}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="time" 
                          type="number" 
                          domain={[Math.min(...saturationData.map(d => d.time)),Math.max(...saturationData.map(d => d.time))]}
                          tickFormatter={(unixTime) => new Date(unixTime).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}
                          tick={{ fontSize: 10 }}
                        />
                        <YAxis 
                          tickFormatter={(value) => Math.floor(value)} 
                          allowDecimals={false}
                          domain={[0, Math.max(1, ...saturationData.map(s => s.value))+1]} 
                          tick={{ fontSize: 10 }}
                          label={{ value: "Nb of satured frame", angle: -90, position: 'insideLeft', style:{fontSize:12} }}
                        />
                        <Tooltip labelFormatter={(label) => new Date(label).toLocaleString()} />
                        <Line 
                          type="monotone" 
                          dataKey="value" 
                          stroke="blue" 
                          dot={{strokeWidth:2, r:1}}
                          activedot={{strokeWidth:2, r:3}}
                          isAnimationActive={false}
                          strokeWidth={0}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ChannelDataGraphs;